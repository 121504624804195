var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-8" },
      [
        _c("pagination", {
          staticClass: "m-l-10",
          attrs: { data: _vm.records, limit: 3 },
          on: { "pagination-change-page": _vm.getRecords },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.records.total && _vm.showPageLength
      ? _c("div", { staticClass: "col-md-4" }, [
          _c("div", { staticClass: "pull-right m-r-10" }, [
            _c(
              "select",
              {
                staticClass: "form-control",
                attrs: { name: "page_length" },
                domProps: { value: _vm.pageLength },
                on: { change: _vm.updateValue },
              },
              _vm._l(_vm.getConfig("pagination"), function (option) {
                return _c("option", { domProps: { value: option } }, [
                  _vm._v(
                    "\n\t\t\t        " +
                      _vm._s(option + " " + _vm.trans("general.per_page")) +
                      "\n\t\t\t      "
                  ),
                ])
              }),
              0
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }