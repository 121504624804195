var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm._l(_vm.customFieldForm.customFields, function (field) {
        return [
          _c("div", { class: ["col-12", _vm.getCustomFieldWidth(field)] }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(field.name)),
                ]),
                _vm._v(" "),
                field.type == "text_input"
                  ? [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: field["value"],
                            expression: "field['value']",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: _vm.getCustomFieldName(field),
                          placeholder: field.name,
                        },
                        domProps: { value: field["value"] },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(field, "value", $event.target.value)
                          },
                        },
                      }),
                    ]
                  : field.type == "numeric_input"
                  ? [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: field["value"],
                            expression: "field['value']",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: _vm.getCustomFieldName(field),
                          placeholder: field.name,
                        },
                        domProps: { value: field["value"] },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(field, "value", $event.target.value)
                          },
                        },
                      }),
                    ]
                  : field.type == "datepicker_input"
                  ? [
                      _c("datepicker", {
                        attrs: {
                          bootstrapStyling: true,
                          placeholder: field.name,
                        },
                        on: {
                          selected: function ($event) {
                            _vm.customFieldForm.errors.clear(
                              _vm.getCustomFieldName(field)
                            )
                          },
                        },
                        model: {
                          value: field["value"],
                          callback: function ($$v) {
                            _vm.$set(field, "value", $$v)
                          },
                          expression: "field['value']",
                        },
                      }),
                    ]
                  : field.type == "multi_line_input"
                  ? [
                      _c("autosize-textarea", {
                        attrs: {
                          rows: "2",
                          name: _vm.getCustomFieldName(field),
                          placeholder: field.name,
                        },
                        model: {
                          value: field["value"],
                          callback: function ($$v) {
                            _vm.$set(field, "value", $$v)
                          },
                          expression: "field['value']",
                        },
                      }),
                    ]
                  : field.type == "checkbox_input"
                  ? _vm._l(field.values, function (value) {
                      return _c(
                        "label",
                        {
                          staticClass: "custom-control custom-checkbox m-t-20",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: field["value"],
                                expression: "field['value']",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              value: value,
                              checked: Array.isArray(field["value"])
                                ? _vm._i(field["value"], value) > -1
                                : field["value"],
                            },
                            on: {
                              click: function ($event) {
                                _vm.customFieldForm.errors.clear(
                                  _vm.getCustomFieldName(field)
                                )
                              },
                              change: function ($event) {
                                var $$a = field["value"],
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = value,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        field,
                                        "value",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        field,
                                        "value",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(field, "value", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "custom-control-label" }, [
                            _vm._v(_vm._s(value)),
                          ]),
                        ]
                      )
                    })
                  : field.type == "radio_input"
                  ? [
                      _c(
                        "div",
                        { staticClass: "radio radio-info p-l-0" },
                        _vm._l(field.values, function (value) {
                          return _c(
                            "div",
                            { staticClass: "form-check form-check-inline" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: field["value"],
                                    expression: "field['value']",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "radio",
                                  id: value,
                                  name: _vm.getCustomFieldName(field),
                                },
                                domProps: {
                                  value: value,
                                  checked: field["value"] == value,
                                  checked: _vm._q(field["value"], value),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.customFieldForm.errors.clear(
                                      _vm.getCustomFieldName(field)
                                    )
                                  },
                                  change: function ($event) {
                                    return _vm.$set(field, "value", value)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: value },
                                },
                                [_vm._v(" " + _vm._s(value))]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  : field.type == "dropdown_input"
                  ? [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: field["value"],
                              expression: "field['value']",
                            },
                          ],
                          staticClass: "custom-select col-12",
                          attrs: { name: _vm.getCustomFieldName(field) },
                          on: {
                            click: function ($event) {
                              _vm.customFieldForm.errors.clear(
                                _vm.getCustomFieldName(field)
                              )
                            },
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                field,
                                "value",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "null" } }, [
                            _vm._v(_vm._s(_vm.trans("general.select_one"))),
                          ]),
                          _vm._v(" "),
                          _vm._l(field.values, function (value) {
                            return _c(
                              "option",
                              { domProps: { value: value } },
                              [
                                _vm._v(
                                  "\n    \t\t\t\t\t\t\t" +
                                    _vm._s(value) +
                                    "\n    \t\t\t\t\t\t"
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.customFieldForm,
                    "prop-name": _vm.getCustomFieldName(field),
                  },
                }),
              ],
              2
            ),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }