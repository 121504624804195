var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-group" }, [
    _vm.position === "prefix"
      ? _c("div", { staticClass: "input-group-prepend" }, [
          _c("span", { staticClass: "input-group-text" }, [
            _vm._v(_vm._s(_vm.symbol)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      ref: "input",
      staticClass: "form-control",
      attrs: {
        type: "number",
        step: _vm.getStep,
        name: _vm.name,
        placeholder: _vm.placeholder,
      },
      domProps: { value: _vm.value },
      on: { input: _vm.updateValue },
    }),
    _vm._v(" "),
    _vm.position === "suffix"
      ? _c("div", { staticClass: "input-group-append" }, [
          _c("span", { staticClass: "input-group-text" }, [
            _vm._v(_vm._s(_vm.symbol)),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }