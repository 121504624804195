var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", { attrs: { for: "" } }, [
      _vm._v(_vm._s(_vm.label || _vm.trans("general.date_range"))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "input-group" },
      [
        _c("datepicker", {
          attrs: {
            bootstrapStyling: true,
            "input-class": "form-control",
            value: _vm.startDate,
            placeholder: _vm.trans("general.start_date"),
            "clear-button": _vm.clearButton,
          },
          on: { selected: _vm.updateStartDate, cleared: _vm.clearStartDate },
        }),
        _vm._v(" "),
        _c("datepicker", {
          attrs: {
            bootstrapStyling: true,
            "input-class": "form-control m-l-10",
            value: _vm.endDate,
            placeholder: _vm.trans("general.end_date"),
          },
          on: { selected: _vm.updateEndDate, cleared: _vm.clearEndDate },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }