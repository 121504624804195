var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "right-sidebar" }, [
    _c("div", { staticClass: "slimscrollright" }, [
      _c("div", { staticClass: "rpanel-title" }, [
        _vm._v(
          " \n            " +
            _vm._s(_vm.trans("general.help")) +
            " \n            "
        ),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "r-panel-body" }, [
        _vm.content
          ? _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
          : _vm._e(),
        _vm._v(" "),
        _vm.loading
          ? _c("div", { staticClass: "loading" }, [
              _c("img", { attrs: { src: "/images/loading.gif" } }),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "btn btn-danger btn-sm right-sidebar-toggle pull-right m-r-10",
      },
      [_c("i", { staticClass: "fas fa-times" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }