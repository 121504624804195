var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "select",
      {
        staticClass: "custom-select",
        staticStyle: { width: "auto" },
        attrs: { name: "hour" },
        domProps: { value: _vm.hour },
        on: { change: _vm.updateHour },
      },
      [
        _c("option", { attrs: { value: "" } }, [
          _vm._v(_vm._s(_vm.trans("general.select_hour"))),
        ]),
        _vm._v(" "),
        _vm._l(_vm.hours, function (hr) {
          return _c("option", { domProps: { value: hr } }, [
            _vm._v("\n        " + _vm._s(_vm.timePadding(hr)) + "\n      "),
          ])
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "select",
      {
        staticClass: "custom-select m-l-10",
        staticStyle: { width: "auto" },
        attrs: { name: "minute" },
        domProps: { value: _vm.minute },
        on: { change: _vm.updateMinute },
      },
      [
        _c("option", { attrs: { value: "" } }, [
          _vm._v(_vm._s(_vm.trans("general.select_minute"))),
        ]),
        _vm._v(" "),
        _vm._l(_vm.mins, function (min) {
          return _c("option", { domProps: { value: min } }, [
            _vm._v("\n        " + _vm._s(_vm.timePadding(min)) + "\n      "),
          ])
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "select",
      {
        staticClass: "custom-select m-l-10",
        staticStyle: { width: "auto" },
        attrs: { name: "meridiem" },
        domProps: { value: _vm.meridiem },
        on: { change: _vm.updateMeridiem },
      },
      _vm._l(_vm.meridiems, function (mer) {
        return _c("option", { domProps: { value: mer.value } }, [
          _vm._v("\n        " + _vm._s(mer.text) + "\n      "),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }