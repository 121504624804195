var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { staticClass: "view-label" }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      !_vm.value
        ? [_c("div", [_vm._v("-")])]
        : [
            _vm.type === "date"
              ? [_c("div", [_vm._v(_vm._s(_vm._f("moment")(_vm.value)))])]
              : [
                  _vm.toUppercaseWord
                    ? _c("div", [_vm._v(_vm._s(_vm.ucword(_vm.value)))])
                    : _c("div", [_vm._v(_vm._s(_vm.value))]),
                ],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }