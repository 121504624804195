var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm._l(_vm.customFields, function (field) {
        return [
          _c(
            "div",
            { class: ["col-12", _vm.getCustomFieldWidth(field)] },
            [
              _c("view-label", {
                attrs: {
                  label: field.name,
                  value: field["value"],
                  type: field.type == "datepicker_input" ? "date" : "string",
                },
              }),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }