var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("notifications", {
    staticClass: "tour-notification",
    attrs: { group: "demo", position: "top right", width: "370px" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function (props) {
          return [
            _c("div", [
              _c("h4", {
                staticClass: "heading",
                domProps: { innerHTML: _vm._s(props.item.title.heading) },
              }),
              _vm._v(" "),
              props.item.title.subheading
                ? _c("h5", {
                    staticClass: "subheading",
                    domProps: {
                      innerHTML: _vm._s(props.item.title.subheading),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "content",
                domProps: { innerHTML: _vm._s(props.item.text) },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "actions" }, [
                _c(
                  "a",
                  { staticClass: "btn btn-close", on: { click: props.close } },
                  [
                    _c("i", { staticClass: "fas fa-times" }),
                    _vm._v(" Close\n          "),
                  ]
                ),
                _vm._v(" "),
                props.item.title.nextUrl
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-next",
                        on: {
                          click: function ($event) {
                            return _vm.next(props.item.title.nextUrl)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fas fa-chevron-right" }),
                        _vm._v(" Next\n          "),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              !props.item.title.disableCloseForever
                ? _c("div", { staticClass: "actions" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-close-forever",
                        on: { click: _vm.closeForever },
                      },
                      [
                        _c("i", { staticClass: "fas fa-bell-slash" }),
                        _vm._v(" Close Forever\n          "),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }