var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    !_vm.type
      ? _c("p", { staticClass: "alert alert-info" }, [
          _c("i", { staticClass: "fas fa-info-circle" }),
          _vm._v(" " + _vm._s(_vm.trans(_vm.module + "." + _vm.tip))),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.type
      ? _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.trans(_vm.module + "." + _vm.tip),
              expression: "trans(module+'.'+tip)",
            },
          ],
          staticClass: "fas fa-info-circle float-right",
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }